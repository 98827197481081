import * as React from 'react';
import Layout from '../components/layout/layout';
import Seo from '../components/seo';
import termsOfService from '../files/termsofservice.pdf';
// Core viewer
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { StaticImage } from 'gatsby-plugin-image';

const TermsOfServicePage = () => {
  let isIE = false;
  if (typeof window !== `undefined`) {
    const ua = window.navigator.userAgent;
    if (ua.indexOf('Trident') !== -1) {
      //IE 11のときの挙動
      isIE = true;
    }
  }
  return (
    <Layout>
      <Seo title="利用規約" />
      {isIE ? (
        <a href={termsOfService}>
          <span>利用規約を表示</span>
        </a>
      ) : (
        <a href={termsOfService} download="利用規約.pdf">
          <StaticImage
            objectFit="contain"
            src="../icons/baseline_file_download_black_48dp.png"
            formats={['auto', 'webp', 'avif']}
            alt={'ダウンロード'}
            width={30}
            height={30}
            imgClassName="ofi"
          />
          <span>ダウンロード</span>
        </a>
      )}
      {isIE ? (
        ''
      ) : (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.10.377/build/pdf.worker.min.js">
          <div
            style={{
              border: '1px solid rgba(0, 0, 0, 0.3)',
              height: '750px',
            }}
          >
            <Viewer fileUrl={termsOfService} />
          </div>
        </Worker>
      )}
    </Layout>
  );
};

export default TermsOfServicePage;
